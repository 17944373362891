import React, { FunctionComponent } from "react"
import { Container } from "../components/common/common"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

interface PoliticaDePrivacidadeProps {
  location: Location
}

const PoliticaDePrivacidade: FunctionComponent<PoliticaDePrivacidadeProps> = ({
  location,
}) => {
  return (
    <Layout bigHeader={false}>
      <SEO
        location={location}
        title="Política de Privacidade"
        type={`WebSite`}
      />
      <Container>
        <h1>Política de Privacidade</h1>
        <p>
          O blog <strong>devleague.com.br</strong> utiliza empresas para a
          apresentação de publicidades de terceiros que podem ser apresentadas
          propagandas durante sua visita em nosso portal.
        </p>
        <p>
          Essas empresas podem coletar de alguma forma dados e informações sobre
          sua visita a nosso blog ou qualquer outro site para que futuramente
          seja utilizado para a apresentação de propagandas ou campanhas
          publicitárias de seu interesse.
        </p>
        <p>
          Está no seu controle impedir que as empresas coletam seus dados. Você
          no papel de visitante, pode configurar seu navegador de internet para
          alertar quando um cookie sendo armazenado em em seu computador. Com
          isso você pode aceitar ou bloquear esse cookies. Não somente esse
          ajuste de configuração está disponível outros ajustes de informações e
          configurações estão disponíveis no seu navegador.
        </p>
        <p>
          Utilizamos o Google como fornecedor de terceiro e ele utiliza cookies
          para apresentar anúncios.
        </p>
        <p>
          O Google e parceiros sendo fornecedor de terceiros, utilizam-se de
          cookies para exibir anúncios com base em visitas anteriores do usuário
          ao seu website ou a outros websites.
        </p>
        <p>
          Os Visitantes podem desativar a publicidade personalizada acessando as
          Configurações de anúncios. Acessando o site www.aboutads.info poderá
          ser desativado o uso de cookies de publicidade personalizada de
          terceiros também.
        </p>
        <p>
          Nosso blog também poderá quando necessário utilizar os cookies e ou
          web beacons para medir e ou monitorar sua visita quando está acessando
          nosso blog.
        </p>
        <p>
          Como alternativa, você como visitante pode acessar o site
          www.aboutads.info para desativar o uso de cookies de publicidade
          personalizada de alguns fornecedores de terceiros.
        </p>
      </Container>
    </Layout>
  )
}

export default PoliticaDePrivacidade
